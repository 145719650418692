import { render, staticRenderFns } from "./StudentSozoReference.vue?vue&type=template&id=614e1d5f&scoped=true&lang=pug&"
import script from "./StudentSozoReference.vue?vue&type=script&lang=ts&"
export * from "./StudentSozoReference.vue?vue&type=script&lang=ts&"
import style0 from "./StudentSozoReference.vue?vue&type=style&index=0&id=614e1d5f&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "614e1d5f",
  null
  
)

export default component.exports