






























import { Component, Vue, Mixins, Watch } from 'vue-property-decorator'
import SidebarSwitcher from '@/components/organisms/v2/SidebarSwitcher.vue'
import ReferenceInformation from '@/components/organisms/sozo/ReferenceInformation.vue'
import StudentBottomPanel from '@/components/organisms/v3/StudentBottomPanel.vue'
import ClassModeMethods from '@/mixins/utils/ClassModeMethods'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

export type Unit = {
  curriculumSUnitId: number
  subjectCode: string
  name: string
}

const GRADES = [1, 2, 3]

@Component({
  components: {
    SidebarSwitcher,
    StudentBottomPanel,
    ReferenceInformation,
    ButtonBase,
  },
})
export default class StudentSozoReference extends Mixins(ClassModeMethods) {
  private subjectNames = {
    su: '算数/数学',
    ei: '英語',
    ko: '国語',
    sh: '社会',
    ri: '理科',
  }

  private get fileSubjectsWP() {
    return GRADES.map((grade) => {
      return {
        name: `中${grade}`,
        type: 'wp',
        grade,
      }
    })
  }

  private get fileSubjectsAS() {
    return GRADES.map((grade) => {
      return {
        name: `中${grade}`,
        type: 'as',
        grade,
      }
    })
  }

  private userId = Vue.prototype.$cookies.get('authGdls').currentApiUserId

  private subjectCode = this.$route.params.subjectCode

  private get selectSubjectUrl() {
    return '/student/sozo/select-subject'
  }
}
