








import { Component, Prop, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'

@Component({
  components: {
    ColoredBox,
    ButtonBase,
  },
})
export default class ReferenceInformation extends Vue {
  @Prop()
  reference!: { type: string; grade: string | number; name: string }

  @Prop()
  subjectCode!: string

  get nameClassObject(): { [key: string]: boolean } {
    return {
      'reference-information__name__sugaku': this.subjectCode === 'su',
      'reference-information__name__kokugo': this.subjectCode === 'ko',
      'reference-information__name__eigo': this.subjectCode === 'ei',
      'reference-information__name__rika': this.subjectCode === 'ri',
      'reference-information__name__syakai': this.subjectCode === 'sh',
    }
  }

  private async redirectUrl(kind: string) {
    const a = document.createElement('a')
    a.target = '_blank'
    a.href = `/student/sozo/watch-reference/${this.reference.type}/${this.subjectCode}/${this.reference.grade}/${kind}`
    a.click()
    a.remove()
  }
}
